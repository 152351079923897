<template>
  <div class="mod-config">
    <template v-if="page === 'list'">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.needNo" placeholder="请输入需求编号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.solutionNo" placeholder="请输入方案编号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <service-item-cascader v-model="dataForm.itemCode"></service-item-cascader>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="dataForm.createTimeRange"
            value-format="yyyy-MM-dd"
            type="daterange"
            unlink-panels
            :clearable="true"
            :pickerOptions="pickerOptions"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        style="width: 100%;">
        <el-table-column
          prop="needNo"
          header-align="center"
          align="center"
          label="需求编号">
        </el-table-column>
        <el-table-column
          prop="solutionNo"
          header-align="center"
          align="center"
          label="方案编号">
        </el-table-column>
        <el-table-column
          prop="company"
          header-align="center"
          align="center"
          label="公司名称">
        </el-table-column>
        <el-table-column
          prop="totalSum"
          header-align="center"
          align="center"
          label="金额">
        </el-table-column>
        <el-table-column
          prop="createTime"
          header-align="center"
          align="center"
          label="创建日期">
        </el-table-column>
        <el-table-column
          prop="creatorName"
          header-align="center"
          align="center"
          label="创建人">
        </el-table-column>
        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="changePage('detail', scope.row.id)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="tac mt-3"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </template>
    <!-- 弹窗, 新增 / 修改 -->
    <need-solution-detail ref="detailSolutionNeed" v-if="page === 'detail'" @goBack="goBack"/>

  </div>
</template>

<script>
  import moment from 'moment'
  import NeedSolutionDetail from './need-solution-detail'
  import ServiceItemCascader from '../../../components/service-item-cascader'
  export default {
    name: 'need',
    components: {
      NeedSolutionDetail,
      ServiceItemCascader
    },
    data () {
      return {
        dataForm: {
          needNo: '',
          solutionNo: '',
          itemCode: '',
          createTimeRange: []
        },
        page: 'list',
        dataList: [],
        solutionList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        pickerOptions: {
          shortcuts: [{
            text: '本月',
            onClick (picker) {
              let begin = new Date()
              begin.setTime(new Date(begin.getFullYear(), begin.getMonth(), 1))
              let end = new Date()
              picker.$emit('pick', [begin, end])
            }
          }, {
            text: '今年至今',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一年',
            onClick (picker) {
              const end = new Date()
              const start = new Date(end.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        statusAudit: {}
      }
    },
    created () {
      this.initDict()
      // 初始化查询时间范围
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
      this.dataForm.createTimeRange = [start, end]
      this.getDataList(true)
    },
    methods: {
      initDict () {
        this.$getDictMap('AUDIT_STATUS', (dict) => {
          this.statusAudit = dict.AUDIT_STATUS
        })
      },
      // 获取数据列表
      getDataList (reset) {
        this.dataListLoading = true
        if (reset) {
          this.pageIndex = 1
        }
        let [beginDate, endDate] = this.dataForm.createTimeRange || []
        this.$http({
          url: this.$http.adornUrl(this.$api.SOLUTION.LIST),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'needNo': this.dataForm.needNo || null,
            'solutionNo': this.dataForm.solutionNo || null,
            'status': 'dqr',
            'itemCode': this.dataForm.itemCode || null,
            'beginDate': beginDate ? moment(beginDate).format('YYYY-MM-DD') : null,
            'endDate': endDate ? moment(endDate).format('YYYY-MM-DD') : null
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList(true)
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      closeCallback () {
        this.addOrUpdateVisible = false
        this.getDataList(true)
      },
      // 切换页面
      changePage (page, id) {
        this.page = page
        this.$nextTick(() => {
          this.$refs[page + 'SolutionNeed'].init(id, 'dqr')
        })
      },
      // 页面回调
      goBack (refresh) {
        this.page = 'list'
        if (refresh){
          this.getDataList(refresh)
        }
      },
      handleChange (val) {
        this.dataForm.skuId = val[2].id
        this.dataForm.skuName = val[2].skuName
      }
    }
  }
</script>

<style scoped>

</style>
